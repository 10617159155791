<template>
  <div>
    <general-table
      ref="employeesTable"
      :api-url="apiUrl"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="false"
      :view-content="false"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :selectable="false"
    >
      <template #cell(photo)="data">
        <div
          v-if="data.item.photo"
          style="width: 100px; height: 100px;"
        >
          <img
            :src="data.item.photo"
            style="height: 100%; max-width: 100%"
          >
        </div>
        <div v-else>
          <img
            src="../../assets/images/avatars/avatr-icon.png"
            width="80"
            height="80"
          >
        </div>
      </template>

      <template #cell(employee_approval)="data">
        <slot
          name="actions"
          v-bind="data"
        >
          <div style="text-align: center;">
            <b-button
              variant="dark"
              style="font-size: 11px;"
              @click="ApprovalRow(data.item.id , data.item.ofice_id)"
            >
              Need Approval</b-button>
          </div>
        </slot>
      </template>
    </general-table>
  </div>
</template>

<script>
import axios from 'axios'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'employees',
      // addType: 'Add Employee',
      // addComponentName: 'add-employee',
      // editComponent: 'edit-employee',
      viewContent: true,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'photo', label: 'photo' },
        { key: 'name', label: 'Employee' },
        { key: 'email', label: 'Email' },
        { key: 'job_title', label: 'Job title' },
        { key: 'mobile', label: 'mobile' },
        { key: 'branch', label: 'Branch' },
        { key: 'employee_approval', label: 'Employee Approval' },

        // { key: 'address', label: 'Address' },
        // { key: 'QRCode', label: 'QRCode' },

        // { key: 'actions' },
      ],
    }
  },
  computed: {
    apiUrl() {
      let base = 'employees?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      return base.slice(0, -1)
    },
  },
  mounted() {},

  methods: {
    ApprovalRow(id, ofice) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to change the approval status of this employee!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Approve',
        cancelButtonText: 'Disapprove',
      }).then(result => {
        this.loading = true
        if (result.isConfirmed) {
          this.$router.push({ name: 'approval-employee', params: { id }, query: { ofice } })
        } else if (result.dismiss === 'cancel') {
          axios
            .post(`/employees/${id}`, {
              approve: 0,
            })
            .then(() => {
              this.$swal('Disapproved!', 'Employee disapproved successfully.', 'warning')
              window.location.reload()
              // EventBus.$emit('reload-data')
            })
            .catch(error => {
              console.error(error)
              this.$swal('Error!', 'There was an issue with the disapproval.', 'error')
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },

    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
  },
}
</script>

<style></style>
